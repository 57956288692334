<template>
  <div class="bg-primary text-white h-14 tracking-wider relative">
    <div
      class="font-medium line-clamp-1 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
    >
      <h1>{{ title }}</h1>
    </div>

    <div class="flex items-center justify-between h-full">
      <div class="w-14 flex-none h-full">
        <button type="button" class="w-full h-full" @click="skipPage" v-if="backable">
          <ChevronLeftIcon class="m-auto" />
        </button>
      </div>

      <div class="flex-none flex items-center">
        <select
          class="field border-none bg-inherit text-white pr-8"
          @change="changeLocal"
          v-if="locales.length"
        >
          <option
            v-for="i in locales"
            :value="i.code"
            :key="i.code"
            :selected="$i18n.locale == i.code"
            v-text="i.name"
          />
        </select>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon } from "@vue-hero-icons/outline";
import { mapGetters } from "vuex";

export default {
  components: {
    ChevronLeftIcon,
  },
  props: {
    title: [String, Number],
    backable: Boolean,
    back: {
      type: Function,
    },
    product: {
      type: Object,
    },
  },
  methods: {
    skipPage() {
      if (!this.product) {
        this.back();
      } else {
        this.$router.push(
          this.localePath(
            `/order?category=${this.product.category_id}&type=${this.product.type}`
          )
        );
      }
    },
    async changeLocal(el) {
      const locale = el.target.value;
      this.$i18n.setLocale(locale);

      // get current path
      const path = this.$route.path;

      window.location.href = this.localePath(path);
    },
  },
  computed: {
    ...mapGetters({
      locales: "app/locales",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
